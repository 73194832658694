<template>
    <div id="dialog-edit-badge-user-component">
        <v-dialog v-model="is_dialog_edit_badge_open" max-width="400px">
            <v-card>
                <v-card-title>
                    <p class="text-uppercase font-18 font-weight-bold mb-2">
                        {{ $t('dialog_edit_badge_component.edit_badge') }}
                    </p>
                </v-card-title>

                <v-card-text class="d-flex flex-wrap justify-space-around gap-5 align-center pb-0">
                    <profile-picture-component :user="fake_user" :width="70" />

                    <div>
                        <div class="d-flex justify-space-around mb-4 mt-4">
                            <div
                                v-for="color in colors"
                                :key="color"
                                :style="'background: ' + color"
                                class="pointer badge-color-selector"
                                @click="fake_user.badge_color = color"
                            />
                        </div>

                        <v-text-field
                            v-model="fake_user.initial"
                            :label="$t('dialog_edit_badge_component.initial')"
                            :rules="[rules.length, rules.required]"
                            color="secondary"
                            filled
                        />
                    </div>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="is_dialog_edit_badge_open = false">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>

                    <v-btn
                        :loading="editing_user_badge"
                        class="btn-secondary-loader"
                        color="secondary"
                        text
                        @click="updateUserBadge()"
                        :disabled="!badge_valid"
                    >
                        <span :class="`${!badge_valid ? 'font-color-light' : 'secondary--text'} font-weight-bold`">
                            {{ $t('global.validate') }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ProfilePictureComponent from '@/components/Profile/ProfilePictureComponent.vue'
import UserRepository from '@/repositories/UserRepository'

export default {
    name: 'DialogEditBadgeUserComponent',
    components: { ProfilePictureComponent },
    data() {
        return {
            is_dialog_edit_badge_open: false,
            colors: ['#0052cc', '#00a3bf', '#00875a', '#ff991f', '#de350b', '#5243aa', '#172b4d'],
            fake_user: {
                initial: '',
                badge_color: '',
            },
            rules: {
                required: (value) => !!value || this.$t('dialog_edit_badge_component.required_field'),
                length: (value) => value.length < 4 || this.$t('dialog_edit_badge_component.three_characters_maximum'),
            },
            editing_user_badge: false,
        }
    },
    computed: {
        badge_valid() {
            return !!this.fake_user.initial && this.fake_user.initial.length < 4
        }
    },
    methods: {
        openDialogEditBadgeUser(user) {
            this.fake_user = {
                id_user: user.id_user,
                initial: user.initial,
                badge_color: user.badge_color,
            }
            this.is_dialog_edit_badge_open = true
        },
        updateUserBadge() {
            this.editing_user_badge = true
            UserRepository.updateUserBadge(this.fake_user.id_user, this.fake_user.initial, this.fake_user.badge_color)
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.showSnackbar('success', this.$t('dialog_edit_badge_component.success_badge_updated'))
                    this.$emit('update:user-badge')
                    this.is_dialog_edit_badge_open = false
                    this.editing_user_badge = false
                })
        },
    },
}
</script>

<style>
.badge-color-selector {
    width: 24px;
    aspect-ratio: 1;
    border-radius: 3px;
    transition: all 0.2s ease;
}

.badge-color-selector:hover {
    transform: scale(1.1);
}
</style>